import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import "vue3-toastify/dist/index.css";

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

const vuetify = createVuetify({
    components,
    directives,
})

const app = createApp(App)

app.config.productionTip = false

app.use(router)
app.use(vuetify)

app.mount('#app')
