import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    { path: '/', name: 'BrandsView', component: () => import('@/views/BrandList') },
    { path: '/customization', name: 'customization', component: () => import('@/views/CustomizationView') },
    { path: '/products', name: 'products', component: () => import('@/views/ProductsView') },
    { path: '/videos', name: 'videos', component: () => import('@/views/VideoCustomization') },
    { path: '/carousel', name: 'carousel', component: () => import('@/views/CarouselView') },
    { path: '/rm-criativo', name: 'rm-criativo', component: () => import('@/views/DynamicCustomization') },
    { path: '/categories', name: 'categories', component: () => import('@/views/CategoriesView') },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;